import React from 'react';
import Layout from '../components/layout';
import { getLayoutItem } from '../helpers/layoutHelper';
import CustomBreadcrumb from "../components/CustomBreadcrumb";
import Seo from "../components/seo"

const Servicespage = ({ pageContext, location }) => {

    function capitalize(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        }
        return splitStr.join(' '); 
     }
    
    const pageRawTitle = location.pathname
    const lastItem = pageRawTitle.substring(pageRawTitle.lastIndexOf('/') + 1)
    const replace = lastItem.replace(/-/g, " ")
    const pageTitle = capitalize(replace)

    const { response } = pageContext;
    const { breadcrumb: { crumbs } } = pageContext;
    const layout_items = Object.values(response['0'].relationships).map(getLayoutItem);
    return (
        <Layout>
            <Seo title={pageTitle} />
            {/* <pre>{JSON.stringify(pageContext, {}, 4)}</pre> */}
            <div className="servicespage">
                <div className="main__container">
                    <CustomBreadcrumb crumbs={crumbs} />
                    {layout_items}
                </div>
            </div>
        </Layout>
    );
};

export default Servicespage;